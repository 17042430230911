import React from 'react';
import PropTypes from 'prop-types';

import BurgerMenu from './BurgerMenu';

import Logo from '../assets/logo.png';
import Facebook from '../assets/icons/facebook-color.svg';
import Twitter from '../assets/icons/twitter-color.svg';
import Instagram from '../assets/icons/instagram-color.svg';
import Youtube from '../assets/icons/youtube-color.svg';
import Linkedin from '../assets/icons/linkedin-color.svg';

const Header = ({ showHashtag }) => {
    const [showMenu, toggleMenu] = React.useState(false);

	return (
		<header>
			<nav className='navigation'>
				<ul className='navigation__list'>
					<li>
						<a
							href='https://www.schindler.com/de/internet/de/home.html'
							target='_blank'
							rel='noopener noreferrer'>
							<img src={Logo} alt='Schindler Logo' />
						</a>
					</li>
					{showHashtag && (
						<li>
							<div className='navigation__event'>#WeElevateArt</div>
						</li>
					)}
					<li>
						<BurgerMenu open={showMenu} onClick={() => toggleMenu(!showMenu)} />
					</li>
				</ul>
            </nav>
            
            <div className={`menu-overlay ${showMenu ? 'active' : ''}`}>
				<nav className='menu-overlay__nav'>
					<ul>
						<li className='menu-overlay__nav-item'>
							<div className='social-channels'>
								<a
									href='https://de-de.facebook.com/schindlerdeutschland/'
									target='_blank'
									rel='noopener noreferrer'>
									<img src={Facebook} alt='Schindler Facebook' />
								</a>
								<a
									href='https://twitter.com/schindlerde'
									target='_blank'
									rel='noopener noreferrer'>
									<img src={Twitter} alt='Schindler Twitter' />
								</a>
								<a
									href='https://www.instagram.com/schindler_de/'
									target='_blank'
									rel='noopener noreferrer'>
									<img src={Instagram} alt='Schindler Instagram' />
								</a>
								<a
									href='https://www.youtube.com/user/SchindlerDeutschland/'
									target='_blank'
									rel='noopener noreferrer'>
									<img src={Youtube} alt='Schindler Youtube' />
								</a>
								<a
									href='https://www.linkedin.com/company/schindler-de/'
									target='_blank'
									rel='noopener noreferrer'>
									<img src={Linkedin} alt='Schindler Youtube' />
								</a>
							</div>
						</li>
						<li className='menu-overlay__nav-item'>
							<button
								id='openConsentWindow'
								className='cookie-btn cookie-btn-primary'
								type='button'>Cookie Einstellungen</button>
						</li>
						<li className='menu-overlay__nav-item'>
							<a
								href='https://www.schindler.com/de/internet/de/system/disclaimer-navigation/datenschutzerklaerung.html'
								target='_blank'
								rel='noopener noreferrer'>
								Datenschutz
							</a>
						</li>
						<li className='menu-overlay__nav-item'>
							<a
								href='https://www.schindler.com/de/internet/de/ueber-schindler/impressum.html'
								target='_blank'
								rel='noopener noreferrer'>
								Impressum
							</a>
						</li>
					</ul>
				</nav>
			</div>
        </header>
	);
};


Header.propTypes = {
	showHashtag: PropTypes.bool.isRequired,
};

export default Header;
